import React from 'react'
import Layout from 'components/shared/Layout'
import SEO from 'components/shared/SEO'
import { Link } from 'gatsby'
import { ArrowRight } from 'react-feather'

const LogPage = ({ location }) => {
  return (
    <>
      <SEO title='Log' description='Things I want to record.' />
      <Layout location={location}>
        <div className='wrapper'>
          <header className='py-14'>
            <h1 className='text-3xl md:text-4xl font-bold mb-4'>Log</h1>
            <p className='text-gray-500 text-sm md:text-base'>
              Things I want to record.
            </p>
          </header>

          <div className='flex-col md:flex-row md:flex space-y-8 md:space-y-0 md:space-x-8'>
            <div className='flex-1 border border-gray-200 hover:bg-gray-100 transform duration-300'>
              <Link to='/log/short-diary'>
                <div className='text-sm text-gray-700 border-b border-gray-200 py-2 px-3'>
                  ✍️
                </div>
                <div className='py-2 px-3'>
                  <h2 className='text-lg'>Short Diary</h2>
                  <div className='text-sm text-gray-400 mt-2'>
                    Records of Thoughts, Feelings and Lessons that are too short
                    to be an article.
                  </div>
                  <div className='text-xs text-gray-400 mt-3 flex justify-end items-center'>
                    <span>Go to Page</span>{' '}
                    <ArrowRight className='ml-1' size='12px' />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default LogPage
